.spinnerFullWidthContainer{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text_box{
    border:none;
    background-color: none;
    height: 50px;
    font-size: 16px;
    padding: 2px 10px;
    color: black;
    margin: 10px;
    width: 100%;
}
.selectorInput{
    margin-left: -2px;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

button[disabled]:hover{
    border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.img-preview{
  width:200px;
}
.btn-small{
  padding:5px;
  font-size: 12px;
  
}