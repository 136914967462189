
@import "./index.scss";
@font-face {
    font-family: 'neon';
    src: local('neon'), url(./assets/fonts/neon/neonbold.otf) format('opentype');
}
*{
    font-family:  apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
 }
a,a:hover,a:visited,a:active{
    text-decoration: none;
}
.MainContent{
    color: white;
}

.button-border-filled{
    background-color:$themeColor;
    color: white;
    border: none;
    font-size: 16px;
    padding: 13px 34px;
    border:2px solid transparent;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 6px;
    // transform: skewX(-10deg) !important;
    text-transform: capitalize;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.button-border-filled:hover{
    background-color: black;
    border: 2px solid $themeColor;


}
.button-border-theme{
    background-color:white;
    color: $themeColor;
    border: 2px solid $themeColor;
    font-size: 14px;
    padding: 10px 35px;
    font-weight: 700;
    text-transform: uppercase;
}
.button-border-theme:hover{
    color: white;
    background-color: $themeColor;
}
.section-separator{
    padding-top: 114px;
}
.section-separator-heading{
    margin-bottom: 60px;
}
.heading{
    color: $themeColor;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    word-spacing: 10px;
    font-size: 47px;
    text-align: center;
}
.heading2{
    color: $themeColor;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
    word-spacing: 5px;
    font-size: 24px;
    line-height: 50px;
}
.sub-heading{
    text-transform: capitalize;
    text-align: center;
    color: black;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 3px;
    word-spacing: 4px;
    line-height: 40px;
    
}
.line{
    width: 100px;
    height: 2px;
    margin: 0 auto;
    background-color: $themeColor ;
}
@media screen and (max-width:700px) {
    .button-border-filled{
        border: 1px solid $themeColor;
        font-size: 12px;
        padding: 7px 24px;
        font-weight: 700;
    }
    .button-border-theme{
        border: 1px solid $themeColor;
        font-size: 12px;
        padding: 7px 24px;
        font-weight: 600;
    }
    .heading{
        font-size: 30px;
    }
    .sub-heading{
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        word-spacing: 2px;
        line-height: 30px;
    }
    .section-separator{
        padding-top: 100px;
    }
}