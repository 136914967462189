@import '../index.scss';
.text-grey{
    color: gray;
}
.image-about{
    cursor: pointer;
    position: relative;
    overflow: hidden;

}

.popup-text-container{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),  url(/assets/images/disco5.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: white !important;
    border-radius: 50%;
    height: 780px;
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 780px;
    padding: 90px;
}
.heading-popup{
    text-align: center;
    flex: 1;
    color: $themeColor;
    word-spacing: 5px;
    font-family: monospace;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.text-popup{
    font-size: 17px;
    flex: 8;
    word-spacing: 4px;
    position: relative;
    letter-spacing: 1.4px;
    color: #fff;
    height: 80%;
    overflow-y: auto;
    text-align: justify;
    font-weight: 600;
    font-family: fantasy;
    line-height: 30px;
}
.about-btn-container {
    flex: 1;
}
.crossSign-secondary{
    position: absolute;
    top: 0px;
    right: 10px;
    cursor: pointer;
    font-weight: 900;
    font-size: 30px;
    z-index: 100;
    // color: red;
    color: $themeColor;
}
.image-about:hover > img{
   transform: scale(1.3);
   transition: 0.8s ease-in-out;
}
.overlay-about{
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
}
.first-overlay{
    background: rgba(0,0,0,0.2);
  }
.image-about:hover > .overlay-about{
    background-color: rgba(0,0,0,0.5);
    transition: 0.5s ease;
}
.about-boxes{
    text-align: center;
}
p.pt-3{
    letter-spacing: 1.2px;
    padding: 30px 0px !important;
    color: black;
}
@media screen and (max-height:750px){
    .crossSign-secondary{
        font-size: 20px;
    }
    .popup-text-container{
        background-color: black;
        width: 100vw;
        height: 100vh;
        border-radius: 0px;
        border-top: 5px solid $themeColor;
        border-left: 5px solid $themeColor;
        padding: 10px;
        margin: 10px;
   
        
    }
    .text-popup{
        height: 50vh;
        font-size: 14px;
        margin-bottom: 5px;
    }
}
@media screen and (max-width:750px) {
    .heading-popup{
        font-size: 17px;
    }
    .about-boxes{
        margin-top: 40px;
    }
    .text-popup{
        word-spacing: 1.2px;
        letter-spacing: 0.5px;
        height: 50vh;
        font-weight: 400;
        font-size: 14px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serifs;
        line-height: 24px;
   
    }
    .popup-text-container{
        width: 100vw;
        height: 100vh;
        border-radius: 0px;
        border-top: 5px solid $themeColor;
        border-left: 5px solid $themeColor;
        padding: 20px;
        margin: 10px;
   
        
    }

}