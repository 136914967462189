.blog-banner{
    background: linear-gradient(0deg,rgba(0,0,0,0.07),rgba(0,0,0,0.07)), url(/src/assets/images/blogs.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
    background-attachment: fixed;
    padding:180px 0px;
    width: 100vw;
}
.blog-text{
    color: white;
    font-size: 24px;
    text-align: center;
    max-width: 800px;
    margin: 30px auto;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media screen and (max-width:800px) {
    .blog-banner{
        background-attachment: scroll;
    }
    .blog-text{
        font-size: 15px;
    }
}