@import '../index.scss';
 #navbar-party{
    padding: 0px 200px;
     display: flex;
     position: fixed;
     z-index: 3;
     width: 100%;
    }
.transparent{
    background-color: transparent !important;
}
.translucent{
    background-color:rgba(0,0,0,1) !important;
}
.navbar-party-logo{
    padding-left: 40px;
}
.navbar-party-logo .logo{
    flex: 1;
    scale: 1.4;
    
    }
.navbar-party-logo .logo{
    width: 100px ;
    height: 100px;

}
.hamburger{
    display: none;
}
.hamline{
    width: 18px;
    height: 3px;
    margin: 4px;
    background-color: $themeColor;
}
.home-container{
        display: none;
}
.home-icon{
    width: 35px;
    margin: 0px;
    height: 35px;
    color: $themeColor;
}
.navbar-party-ul{
    list-style: none;
    flex: 2;
    display: flex;
    align-items: center;
    justify-content:center;

}
.navbar-party-ul li{
padding: 0px 4px;
}

.navbar-party-ul li>a{
   color: white;
   line-height: 100px;
   font-size: 14px;
   text-decoration: none;
   padding: 8px 16px;
   text-transform: uppercase;
   font-weight: 600;
   letter-spacing: 2px;
}

.activatedTab{
    border: 1.7px solid white;
}
.navbar-party-ul li>a:hover{
   border: 1.7px solid white;
   transition: 0.4s ease;
   
 }
 @media screen and (max-width:1200px) {
    #navbar-party{
        padding: 0;
    }
 }
 @media screen and (max-width:850px) {
    .hamburger{
        display: block;
        position: absolute;
        top:30px;
        right: 20px;
        border-radius: 4px;
        padding: 2px;
    }

    .home-container{
        display: block;
        position: absolute;
        top:30px;
        left: 20px;
        border-radius: 4px;
        padding: 2px;
}
    .instagram-nav{
        left: 70px;
    }
    #navbar-party{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        padding: 0;
        position: fixed;
        width: 100%;

    }
    .navbar-party-logo{
        display: block;
        text-align: center;
    }
    .navbar-party-ul-height0{
        overflow-y: hidden;
        // transition: 0.5s;
        height: 0px;
    }
    .navbar-party-ul-height100{
        height: 100%;
        // transition: 0.5s;
    }
    .navbar-party-ul{
      display: block;
      transition: 0.9s;
      padding-inline-start: 0px;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding: 0 40px;
      background-color: rgba(0,0,0,0.83);

    }
    .navbar-party-ul li{
        text-align: center;

     }
    .navbar-party-ul li > a {
        line-height: 50px;
        display: block;
     }
 }

 @media screen and (max-width:350px) {
    .home-icon{
        width: 24px;
        margin: 0px;
        height: 24px;
        color: $themeColor;
    }
    .instagram-nav{
        left: 55px;
    }
 }
 