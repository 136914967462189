@import '../index.scss';
.bullImg{
    width:300px;
    margin:0 auto;
    margin-top: 150px;
    display:block;
}
.bullHeading{
  margin-bottom:150px;
  font-family:cursive;
  font-weight: 800;
  color:$themeColor
}