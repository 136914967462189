@import '../index.scss';

.image{
    cursor: pointer;
    position: relative;
}
.overlay-shadow{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.57);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.frame-pic{
border: 10px solid white;
max-width: 700px;
position: relative;
}
.frame-pic-image{
    width: 100%;
}
.crossSign{
    position: absolute;
    top: 0px;
    right: 10px;
    cursor: pointer;
    font-weight: 900;
    font-size: 30px;
    z-index: 100;
    color: white;
}
.overlay-gallery-top{
    position: absolute;
    bottom:0;
    left: 0;
    width: 100%;
    height: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.67);

}
.overlay-gallery-top-icon{
    color: #fff;
    text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #0fa,
    0 0 82px #0fa,
    0 0 92px #0fa,
    0 0 102px #0fa,
    0 0 151px #0fa;
    font-size: 30px;
    display: none;
}
.image:hover > .overlay-gallery-top{
    height: 100%;
    transition: 0.2s;
}
.image:hover > .overlay-gallery-top > .overlay-gallery-top-icon {
   display: block;
}
.p-6{
    padding: 0px 100px;
}
@media screen and (max-width:800px) {
    .p-6{
        padding: 0px 20px;
    }
}
@media screen and (max-width:500px) {
    .p-6{
        padding: 0px 0px;
    }
}