.underConstructionBanner{
    background: url('/assets/images/construction.jpeg');
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
    background-position: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.construction_container{
    text-align: center;
    // max-width: 900px;

}
.construction_container img{
    width: 400px;
}
.contruction_text{
    text-align: center;
    font-size: 60px;
    letter-spacing: 3px;
    word-spacing: 10px;
    text-transform: capitalize;
    font-weight: 900;
    color: white;

}
@media screen and (max-width:1200px),
screen and (max-height: 700px) {
    .contruction_text{
        font-size: 40px;
        letter-spacing: 1px; 
         word-spacing: 4px;
    }
}
@media screen and (max-width:800px) {
    .contruction_text{
        font-size: 30px;
        letter-spacing: 1px; 
         word-spacing: 4px;
    }
    .construction_container img{
        width: 200px;
    }
}
@media screen and (max-width:500px) {
    .contruction_text{
        font-size: 20px;
        letter-spacing: 1px; 
         word-spacing: 4px;
    }
}