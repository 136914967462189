@import "../index.scss";
@font-face {
    font-family: 'budmo';
    src: local('budmo'), url(../assets/fonts/budmo/budmojiggler.otf) format('opentype');
}
@font-face {
    font-family: 'congrats';
    src: local('congrats'), url(../assets/fonts/congrats-script/CongratsScript.otf) format('opentype');
}
@font-face {
    font-family: 'lucy';
    src: local('lucy'), url(../assets/fonts/lucy/lucy.ttf) format('truetype');
}
@font-face {
    font-family: 'neon';
    src: local('neon'), url(../assets/fonts/neon/neonbold.otf) format('opentype');
}
@font-face {
    font-family: 'kimberly';
    src: local('kimberly'), url(../assets/fonts/kimberly/JandaCelebrationScript.ttf) format('truetype');
}
.banner{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)), url('/assets/images/banner22.jpeg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    width: 100vw;
}
.videoContainer{
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   position: absolute;
   z-index: -1;
   width: 100%;
   height: 850px;
   overflow-y: hidden;
}
.bannerVideo{
    width: 100%;
    height: auto;
    position: absolute;
    top:0;
    left: 0;
    z-index: -1;
}
.banner-text{
    padding-top: 210px;
    text-align: center;
    padding-bottom: 300px;
    color: $themeColor;
 
}
.banner-upper-text{
    text-align: center;
    font-size: 20px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #fff;
    text-shadow:
    0 0 7px #fff,
    0 0 10px rgb(255, 255, 255),
    0 0 21px rgb(255, 255, 255),
    0 0 42px rgb(255, 0, 153),
    0 0 82px rgb(200, 0, 255),
    0 0 92px rgb(255, 123, 0),
    0 0 102px rgb(170, 0, 255),
    0 0 121px rgb(255, 0, 179);
    padding-bottom: 30px;
    font-family: 'neon';
}
.banner-mid-text{
    text-align: center;
    font-size: 80px;
    letter-spacing: 7.4px;
    // color: rgb(250, 114, 114);
    color: #fff;
    text-shadow:
    0 0 7px #fff,
    0 0 10px rgb(255, 255, 255),
    0 0 21px rgb(255, 255, 255),
    0 0 42px rgb(255, 0, 153),
    0 0 82px rgb(200, 0, 255),
    0 0 92px rgb(255, 123, 0),
    0 0 102px rgb(170, 0, 255),
    0 0 121px rgb(255, 0, 179);
    text-transform: uppercase;
    font-family: budmo !important;
    padding-bottom: 20px;
}
.banner-bottom-text{
    text-align: center;
    font-size: 15px;
    letter-spacing: 1px;
    text-shadow: 1px 1px $themeColor;
    font-weight: 900;
    color: white;
    padding-bottom: 50px;
    font-family:cursive;
}
@media screen and (max-height:700px) {
    .banner-btn{
        font-size: 12px;
        padding: 7px 24px;
        font-weight: 700;
    }
  
}
@media screen and (max-width:1100px) {

    @media screen and (max-height:700px) {
        .banner-text{
            padding-top: 130px;
            text-align: center;
            padding-bottom: 170px;
            color: $themeColor;
         
        }
      
    }
.banner-bottom-text{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
}
.videoContainer{  
   height: 100vh;
}

.bannerVideo{
    height: 100vh;
    object-fit: cover;

} 
.banner-mid-text{
    text-align: center;
    font-size: 21px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: rgb(228, 122, 122);
    text-shadow:
    0 0 7px $themeColor,
    0 0 10px rgb(89, 74, 74),
    0 0 21px rgb(164, 13, 13),
    0 0 42px rgb(171, 97, 141),
    0 0 82px rgb(188, 6, 238),
    0 0 92px rgb(255, 123, 0),
    0 0 102px rgb(248, 247, 249),
    0 0 121px rgb(250, 243, 248);
    padding-bottom: 30px;
    font-family: 'neon';
}
.banner-mid-text{
    font-size: 2em;
}
}
@media screen and (max-width:700px) {
    .banner{
        background-attachment: scroll;
    }
}