@import  "../index.scss";

@keyframes rotationS {
    to{
        transform: rotate(0deg);
    }
    from{
        transform: rotate(360deg);
    }
}
@keyframes rotationSA {
    to{
        transform: rotate(0deg);
    }
    from{
        transform: rotate(-360deg);
    }
}
.spinner_container{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    margin: 0px auto;
    border-left: 4px solid $themeColor;
    border-top: 4px solid $themeColor;
    border-bottom: 4px solid transparent;
    border-right: 4px solid transparent;
    animation:rotationS 1.5s linear infinite;
    margin-top: 70px;
}
.spinner_outer{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border-left: 4px solid purple;
    border-top: 4px solid purple;
    border-bottom: 4px solid transparent;
    border-right: 4px solid transparent;
    position: relative;
    margin: 0px auto;
    margin-top: 12px;
    animation:rotationS 1s linear infinite;
}

.spinner_inner{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-left: 4px solid orange;
    border-top: 4px solid orange;
    border-bottom: 4px solid transparent;
    border-right: 4px solid transparent;
    animation:rotationS 0.5s linear infinite;
    position: relative;
    margin: 0px auto;
    margin-top: 12px;
}