.activeSignal, .nonActiveSignal{
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: greenyellow;
}

.nonActiveSignal{
    background-color: red;
}