@import '../index.scss';
footer{
    background: linear-gradient(0deg,rgba(0,0,0,0.07),rgba(0,0,0,0.07)), url('/assets/images/footer.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    color: white;
    padding: 100px 0;
}
.footerLogoImg{
    width: 60%;
    margin-top: -50px;
}
.footer-col-text{
    border-left: 1px solid #ccc;
    padding-left: 40px;
}
.icon-footer{
    padding: 10px;
    font-size: 28px;
}
.footer-col-text a{
    text-decoration: none;
    color:$themeColor;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: 1px;
}
.TandC{
    font-size: 12px;
    color: $themeColor;
}
.rights_reserved{
    margin-top: 70px;
}
.footer-container{
    padding-top: 8px;
}

@media screen and (max-width:800px) {
    footer{
    background-attachment: scroll;
    }   
    footer aside{
        text-align: center;
        padding-top: 10px !important;
    }
    .footer-col-text{
        border:none;
        padding-left: 0px;
    }
    
}