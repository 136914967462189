@import '../../index.scss';
.login_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}
.login_heading{
    color: $themeColor;
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 30px;
}
.login_main{
    width: 640px;
    padding: 100px 60px;
    border:1px solid #ccc;
    color: black;
}
.text_box{
    border:1px solid #ccc;
    margin-top: 27px !important;
}
.textarea_box{
    width: 100%;
    height: 200px;
    margin-top: 27px !important;
    border:1px solid #ccc;
    border-radius: 3px;
    padding: 10px;

}
.textarea_box:focus,textarea_box:active{
    border:1px solid $themeColor;
    
}
.login-btn{
   margin:0 auto;
   display: block;
   padding: 7px 30px;
   margin-top: 30px;
}
.error{
    color:red;
    font-size: 13px;
    margin-top: 10px;
    margin-left: 10px;
}
@media screen and (max-width:700px) {

    .login_main{
    width: 96%;
    padding: 100px 20px;
    border:1px solid #ccc;
    color: black;
}
}