.formRegistration{
    max-width: 700px;
    margin:20px auto;
    padding: 20px;
}
.text_box_half,.text_box_half_2{
    float: left;
    width: 49%;
}
.text_box_half_2{
    position: relative;
    left: 13px;
}
.mt-3{
    margin-top: 30px !important;
}