@import '../index.scss';

.banner-events{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2)), url('/assets/images/EventsBanner.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 80px 0px;
    -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
    background-attachment: fixed;
    width: 100vw;
}
.custom-white{
    color: #fff;
}

.heading-6{
    font-size: 12px;
    text-align: center;
    color: $themeColor;
}
.padding0{
    padding-top: 0px;
    margin-top: 120px;
}
.custom-container{
    max-width: 1500px;
    padding: 100px;
    margin: 0 auto;
}
.event-boxes{
    color: #fff;
    margin: 20px 5px;
    background-color: rgba(0, 0, 0, 0.4);
}
.popup-text-container-event{
    width: 1000px;
    background-color: #000;
    background:  linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/assets/images/eventPopup.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 13px;
    background-position: center;
    display: flex;
    flex-direction: column;
    height: 80vh;
}

.eventHeading{
    text-transform: capitalize;
    flex: 1;
}
.eventTextpopup{
    flex: 8;
    color: white;
    padding: 10px 30px;
    height: 40vh;
    letter-spacing: 1.9px;
    word-spacing: 4px;
    font-size: 17px;
    overflow-y: auto;
    font-family:fantasy
}
.event-popup-button-container{
    flex: 1;
}
.eventTextpopup p{
    font-family:fantasy !important;
}


.p-3{
    padding: inherit 150px;
}
.eventBtn-popup{
    padding: 10px 52px;
}
.eventBtn{
    padding: 10px;
    width: 100%;
    border: 2px solid $themeColor;
    background-color: transparent;
}
.eventBtn:hover{
    background-color: $themeColor;
}

.event-image-container{
    width: 100%;
}

.event-image{
    width: 100%;
}
@media screen and (max-height:500px){
    .eventTextpopup{
        height: 45vh;
    }
}
@media screen  and (max-width: 1100px){
    .eventTextpopup{
        height: 45vh;
    }
    .popup-text-container-event{
        width: 90vw;
        height: 90vh;
    }
    .eventTextpopup{
        padding: 10px 20px;
        font-size: 15px;
        letter-spacing: 0.5px;
        word-spacing: 1.2px;
        text-align: justify;
    }
    .eventTextpopup{
        padding: 10px 20px;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
    }
    .eventTextpopup p{
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
    }
    .eventTextpopup h1{
       font-size: 18px;
    }
    .eventTextpopup h2{
        font-size: 16px;
     }
    .custom-container{
        padding: 40px;
    }
    .eventBtn{
        padding: 8px;
        border: 1px solid $themeColor;
        font-size: 12px;
    }
    .eventBtn-popup{
        padding: 6px 34px;
    }
}
@media screen  and (max-width: 800px){
  
    .custom-white{
        color: #000;
    }
    .custom-container{
        padding: 10px;
    }
    .eventTextpopup{
        padding: 10px 20px;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
    }
   
    .banner-events{
        background-attachment: scroll;
    }
    .eventBtn{
        padding: 8px;
        border: 1px solid $themeColor;
        font-size: 10px;
    }
  
    .banner-events{
        background:none;
        padding:0px;
    }
    .event-boxes{
        background-color: #fff;
        padding: 4px;
        margin: 20px 5px;
        color: #000;
        
    }
    .event-boxes:hover{
        background-color: black;
        color: #fff;
        cursor: pointer;
        }
    .event-boxes:hover  .eventBtn{
        color: #fff !important;
    }
    .eventBtn{
        color: black;
    }

}