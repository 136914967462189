.container-nf{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
a,a:link,a:hover,a:visited{
    text-decoration: none;
}
.notFoundText{
    text-align: center;
    font-size: 60px;
    letter-spacing: 3px;
    word-spacing: 10px;
    text-transform: uppercase;
    font-weight: 900;
}
@media screen and (max-width:1200px),
screen and (max-height: 700px) {
    .notFoundText{
        font-size: 40px;
        letter-spacing: 1px; 
        word-spacing: 4px;
    }
}
@media screen and (max-width:800px) {
    .notFoundText{
        font-size: 30px;
        letter-spacing: 1px; 
        word-spacing: 4px;
    }
    
}
@media screen and (max-width:500px) {
    .notFoundText{
        font-size: 20px;
        letter-spacing: 1px; 
        word-spacing: 4px;
    }
}